import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import HeroImage from "../images/case-study-two-banner.jpg";
import ClientImage from "../images/client-img-two.jpg";
import CaseStudyCta from "../images/case-study-cta.png";
import CaseStudyCtaImg from "../images/case-study-cta-img.png";
 
import UpdateIcon from "../assets/icons/update-icon.svg";
import AnalyticsIcon from "../assets/icons/analytics-icon.svg";  
import WeekBoostIcon from "../assets/icons/week-boost-icon.svg";
import AbTestingIcon from "../assets/icons/ab-testing-case-icon.svg";
 
import ContentModelIcon from "../assets/icons/content-model-icon.svg"; 


const CaseStudyTwoPage = () => {
    return (
        <div className="template-case-study case-study-two header-position">
            <Layout>
                <div className="hero-banner mb-104">
                    <div className="container"> 
                        <div className="hero-banner-wrap">
                            <div className="left-block">
                                <div className="banner-text">
                                    <div className="category-listing">
                                        <span class="extra-small-text font-light-color font-capital">digital services</span>
                                        <span class="extra-small-text font-light-color font-capital">Content management/seo</span>
                                        <span class="extra-small-text font-light-color font-capital">CMS </span>
                                    </div>
                                    <h2>Unleashing <br></br>creative potential <br></br>with headless CMS</h2>
                                    <p className="mb-40">How we eliminated developer dependency for the marketing team to provide complete autonomy for creating content experiences with no-code headless CMS?</p>
                                    <ul class="highlighted-title-text d-flex">
                                        <li className="font-light-color font-medium font-capital"><h2 className="font-medium">4 weeks</h2> WEBSITE LAUNCHED IN</li>
                                        <li className="font-light-color font-medium font-capital"><h2 className="font-medium">30X</h2>faster static page updates</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="right-block">
                                <img loading="lazy" src={HeroImage} title="Unleashing creative potential with headless CMS" alt="Unleashing creative potential with headless CMS" />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="image-title-section mb-208">
                    <div className="container-medium">
                        <div className="image-title-inner custom-row d-flex flex-wrap row-2 align-v-center">
                            <div className="title-block grid">
                                <h2 className="mb-24">The Client</h2>
                                <p>Our Client is one of the fastest growing U.S.-based technology firms with three Inc. 5000 awards and 18 years of experience enabling digital transformation for the most innovative brands globally. </p>
                            </div>
                            <div className="image-block grid">
                                <img loading="lazy" src={ClientImage} title="The Client" alt="The Client" />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="full-content-section mb-80">
                    <div className="container-small">
                        <div className="full-content-inner">
                            <h2 className="mb-24">The Challenges</h2>
                            <p>The Client faced challenges that many growing technology solutions-oriented companies run into, such as keeping up their increasing brand presence and value. This led our Client to embark on a website revamping and rebranding initiative. It was time for their marketing website to match the brand's vision and reflect premium quality content, look, and feel as their customers expect from their digital products.</p>
                            <p>To achieve this goal, the Client was determined to find a solution that met three critical criteria.</p>
                            <ul className="content-ul">
                                <li>Autonomy - An easier way to create and manage content across web and app.</li>
                                <li>Faster content publishing - The design and marketing team must have complete control over every aspect of the website with low to no dependency on developers. As with developer dependencies, simple copy changes that should take minutes to implement took days, and more significant projects took weeks — if not months — to get live</li>
                                <li>Reliability - A holistic solutions to manage all website related tasks - content workflow, digital content library, CDN and more.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="cta-banner case-study-top-cta mb-208">
                    <div className="container-small">
                        <div className="cta-banner-inner">
                            <div className="custom-row-big d-flex row-2 align-v-center">
                                <div className="grid">
                                    <div className="image-block">
                                        <img loading="lazy" src={CaseStudyCtaImg} title="CTA Banner Image" alt="CTA Banner Image" />
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="text-block"> 
                                        <h5 className="mb-32">Are you looking for the most innovative CMS to achieve autonomy for your design and marketing teams?</h5>
                                        <Link className="button black-button button-small" to="/contact/">Talk to a CMS experts</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="full-content-section mb-208">
                    <div className="container-small">
                        <div className="full-content-inner">
                            <h2 className="mb-24">The Solution</h2>
                            <p>The Client wanted a platform that was constantly innovating and easy to integrate with their existing systems like analytic and performance measuring tools. This meant a Content Management System that works for the entire eCommerce team and provids abilities to manage and update sitewide elements without requiring a full-length development process. </p>
                            <p className="font-bold font-dark-color mb-24">Digital Products</p>
                            <ul>
                                <li className="d-flex align-v-center mb-16">
                                    <div className="icon-block headless-cms">
                                        <ContentModelIcon />
                                    </div>
                                    <p className="font-bold font-dark-color">Headless CMS</p>
                                </li> 
                            </ul>
                            <p>After evaluating multiple software, they settled for Experro CMS as they quickly discovered that to achieve a paradigm shift, they needed the abilities and functionalities offered by Experro CMS. A complete solution that empowered the design and marketing teams to create powerful website experiences using no code drag-and-drop visual editor, reusable content models, in-built SEO, and workflow management capabilities.</p>
                            <p>It took the Experro Enterprise team 4 weeks to deliver the end-to-end CMS solution from onboarded with the Client's team with the help of a dedicated account representative who provided training, technical support, and best practices to get the most out of the CMS tool. </p>
                            <p>The design and marketing teams are crafting beautiful content experiences to inspire actions.</p>
                        </div>
                    </div>
                </div>



                <div className="full-content-section mb-208">
                    <div className="container-small">
                        <div className="full-content-inner">
                            <h2 className="mb-24">The Results</h2>
                            <p>With a CMS that is built, keeping the designers, content creators and marketers in mind. The Client’s teams now have the autonomy to make changes on their website with speed using built-in libraries for all digital multimedia assets powered with CND and reliability from a tool that offers 99.9% SLA for stable web pages.</p>
                            <p>With A/B testing for variations of other page and their contents, the Client could cater to all the customer segments with surety.</p>
                            <p>With Experro CMS the Client and users figured out- there's no code faster than co-code.</p>
                            <div className="content-point-box">
                                <ul class="highlighted-title-text custom-row-big row-2 d-flex flex-wrap">
                                    <li className="font-light-color grid font-medium font-capital d-flex">
                                        <div className="icon-block">
                                            <UpdateIcon />
                                        </div>
                                        <div className="content-title">
                                            <h3 className="font-medium">27%</h3>Improvement in <br></br>conversion rate 
                                        </div> 
                                    </li>
                                    <li className="font-light-color grid font-medium font-capital d-flex">
                                        <div className="icon-block">
                                            <AnalyticsIcon />
                                        </div>
                                        <div className="content-title">
                                            <h3 className="font-medium">5% points.</h3>Boost in website impressions
                                        </div> 
                                    </li>
                                    <li className="font-light-color grid font-medium font-capital d-flex">
                                        <div className="icon-block">
                                            <WeekBoostIcon />
                                        </div>
                                        <div className="content-title">
                                            <h3 className="font-medium">4 Weeks </h3>Launched a new<br></br>marketing website. 
                                        </div> 
                                    </li>
                                    <li className="font-light-color grid font-medium font-capital d-flex">
                                        <div className="icon-block">
                                            <AbTestingIcon />
                                        </div>
                                        <div className="content-title">
                                            <h3 className="font-medium">30X</h3>Improved loadtime<br></br> for static pages
                                        </div> 
                                    </li>
                                </ul>
                            </div>
                        
                        </div>
                    </div>
                </div>

            
                <div className="cta-banner case-study-cta-banner mb-208">
                    <div className="container-medium">
                        <div className="cta-banner-inner">
                            <div className="custom-row-big d-flex row-2 align-v-center"> 
                                <div className="grid">
                                    <div className="text-block">
                                        <h4>To learn more about the Experro</h4>
                                        <p className="mb-40">We’re helping B2B, B2C, D2C, and retailers to reinvent their digital experiences for a customer-first future.</p>
                                        <Link className="button black-button" to="/contact/">Contact Us</Link>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="image-block">
                                        <img loading="lazy" src={CaseStudyCta} title="CTA Banner Image" alt="CTA Banner Image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="experro-more-details-section mb-208">
                    <div className="container-medium">
                        <h3 className="mb-32 text-center font-medium">More Customer Success Stories</h3>
                        <div className="icon-with-text-block icon-with-text-border-box">
                            <div className="custom-row d-flex flex-wrap row-2">
                                <div className="grid">
                                    <div className="item-inner"> 
                                        <div className="text-block">
                                            <h6>Delivering Omnichannel Shopping Experience</h6>
                                            <p className="small-text">How we built consistent customer experiences by implementing the Digital Experience Platform in record time?</p>
                                            <Link className="btn-link right-arrow" to="/case-study-one/">Read Story</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner"> 
                                        <div className="text-block">
                                            <h6>Connecting shoppers and Product with AI Discovery</h6>
                                            <p className="small-text">How we helped our B2B client to increase search revenue by helping shoppers find the exact SKU within seconds?</p>
                                            <Link className="btn-link right-arrow" to="/case-study-three/">Read Story</Link>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>

                
            </Layout>
        </div>
        
    );
}

export default CaseStudyTwoPage;